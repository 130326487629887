import { makeAutoObservable } from "mobx";
import { PostApprovmentDetailDTO, PostApprovmentListResultDto, NonKaAproveDTO, NonKaDenyDTO, NonKaSendBackDTO, PostAproveDTO, PostDenyDTO, BlacklistUserDTO } from "../models/models";
import agent from "../api/agent";

export default class postApproveStore {

    posts: PostApprovmentListResultDto | undefined = undefined;
    postDetails: PostApprovmentDetailDTO[] = [];

    loadingInitial = false;
    modalLoadingInitial = false;
    historyLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoadingInitial = (val: boolean) => {
        this.loadingInitial = val;
    }
    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }
    setHistoryLoadingInitial = (val: boolean) => {
        this.historyLoadingInitial = val;
    }


    loadPosts = async () => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.PostApprovment.list();
            if (result) {

                this.posts = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;

        } catch (error) {
            console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getPostApprovmentDetail = async (id: string): Promise<PostApprovmentDetailDTO | null> => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.PostApprovment.detail(id);
            return result;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }


    approvePost = async (req: PostAproveDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.PostApprovment.approve(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    denyPost = async (req: PostDenyDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.PostApprovment.deny(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    blacklistUser = async (req: BlacklistUserDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.PostApprovment.blacklistUser(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

}