import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store'
import { BlacklistUserDTO, PostAproveDTO, PostDenyDTO } from '../../app/models/models'
import { toast } from 'react-toastify'
import LoadingComponent from '../../app/layout/LoadingComponent'
import { history } from "../..";

interface Props {
  postId: string;
  handleClose: any;
}
export default observer(function DetailsPost(props: Props) {
  const { postApproveStore } = useStore();


  const [name, setName] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const [imageFileName, setImageFileName] = useState('');
  const [uploadedImage, setUploadedImage] = useState('');

  const [denyConfirmModalOpen, setDenyConfirmModalOpen] = useState<boolean>(false);
  const [approveConfirmModalOpen, setApproveConfirmModalOpen] = useState<boolean>(false);
  const [sendBackConfirmModalOpen, setBlacklistConfirmModalOpen] = useState<boolean>(false);




  useEffect(() => {
    const fetchData = async () => {

      const data = await postApproveStore.getPostApprovmentDetail(props.postId);
      if (data) {
        setName(data.userName);
        setSurname(data.userSurname);
        setDescription(data.description);
        setLocation(data.location);
        setUserId(data.userId);
        setUserPhoneNumber(data.userPhoneNumber);
        setStatus(data.status);
        setImageFileName(data.url)
        setUploadedImage(data.url)
      }
    }
    fetchData()
      .catch(console.error);
  }, [])



  function CloseSubmit() {
    props.handleClose(false);
  }


  async function DenySubmit() {

    setDenyConfirmModalOpen(true);
  }

  async function ConfirmDenySubmit() {
    try {
      let pd = new PostDenyDTO();
      pd.id = props.postId;
      var res = await postApproveStore.denyPost(pd);

      if (res === 200) {
        props.handleClose(true)
        toast.success('İşlem reddedilmiştir.')
        await postApproveStore.loadPosts();

      }
      else {
        toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ");
      }
      setDenyConfirmModalOpen(false);
    }
    catch (error) {
      console.log(error);
    }

  }

  async function BlacklistSubmit() {

    setBlacklistConfirmModalOpen(true);
  }

  async function ConfirmBlacklistSubmit() {
    try {
      let blp = new BlacklistUserDTO();
      blp.id = props.postId;
      var res = await postApproveStore.blacklistUser(blp);
      if (res === 200) {
        props.handleClose(true)
        toast.success('Kullanıcı Blacklist\'e alındı.')
        await postApproveStore.loadPosts();
      }
      else {
        toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  async function ApproveSubmit() {

    setApproveConfirmModalOpen(true);
  }

  async function ConfirmApproveSubmit() {
    try {
      let pa = new PostAproveDTO();
      pa.id = props.postId;

      var res = await postApproveStore.approvePost(pa);

      if (res === 200) {
        props.handleClose(true)
        toast.success('İşlem onaylanmıştır.')
        await postApproveStore.loadPosts();
      }
      else {
        toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
      }
      setApproveConfirmModalOpen(false);
    }
    catch (error) {
      console.log(error);
    }
  }
  const handleCancel = () => {
    setDenyConfirmModalOpen(false);
    setApproveConfirmModalOpen(false);
    setBlacklistConfirmModalOpen(false);
  }





  if (postApproveStore.modalLoadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <Confirm className='denyConfirmModal'
        open={denyConfirmModalOpen}
        content='İşlem reddedilecektir. Devam etmek istiyor musunuz?'
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { ConfirmDenySubmit(); }}
        size='mini'
      />
      <Confirm className='approveConfirmModal'
        open={approveConfirmModalOpen}
        content='İşlem onaylanacaktır. Devam etmek istiyor musunuz?'
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { ConfirmApproveSubmit(); }}
        size='mini'
      />
      <Confirm className='sendBackConfirmModal'
        open={sendBackConfirmModalOpen}
        content="Kullanıcı Blacklist'e alınacaktır. Devam etmek istiyor musunuz?"
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { ConfirmBlacklistSubmit(); }}
        size='mini'
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='formTable' style={{ width: '50%', padding: '20px' }}>
          <div className='header' style={{ textAlign: 'left' }}>Yüklenen Belgeler</div>
          <hr className='modalLine'></hr>
          <div className="documentsWrap">
            <div className="uploadedPicContainer">
              <div className="uploadedPicInner">
                <img alt="preview image" style={{ maxHeight: '220px', borderRadius: '20px' }} src={uploadedImage} />
                <a href={imageFileName} className="selectedFileName" > Resmi İndir</a>
              </div>


            </div>
            <div className="uploadedDescription" style={{ margin: '10px 0' }}>
              <textarea className='ActiveInput descriptionInput' disabled placeholder='İçerik açıklaması bulunmamakta'
                style={{ width: '100%' }} value={(description == null) ? '' : description} />
            </div>
            <div className="uploadedLocation">
              <input className='ActiveInput' type='text' disabled placeholder='İçerik konum bilgisi bulunmamakta'
                style={{ width: '100%' }} value={(location == null) ? '' : location} />
            </div>
          </div>

        </div>
        <div className='formTable' style={{ width: '50%', padding: '20px', marginTop: '10%' }}>
          <div className='header' style={{ textAlign: 'left' }}>Kullanıcı Bilgileri</div>
          <hr className='modalLine'></hr>
          <table style={{ padding: "0" }}>
            <tbody>
              <tr>
                <td>Kullanıcı Adı</td>
                <td>
                  <input className='DisabledInput' type='text'
                    style={{ width: '200px' }} value={(name == null) ? '' : name} disabled />
                </td>
              </tr>
              <tr>
                <td>Kullanıcı Soyadı</td>
                <td>
                  <input className='DisabledInput' type='text'
                    style={{ width: '200px' }} disabled value={(surname == null) ? '' : surname} />
                </td>
              </tr>
              <tr>
                <td>Kullanıcı UserID</td>
                <td>
                  <input className='DisabledInput' type='text' style={{ width: '200px' }} disabled value={(userPhoneNumber == null) ? '' : userPhoneNumber} />
                </td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>

      <div className="detailsPageContainer" style={{ padding: "0 0 0 20px" }}>
        <div style={{ marginTop: '30px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
            {status == 'P' ?
              <input className='button mr20 dark-gray-button' onClick={() => { BlacklistSubmit() }} type='submit' value="Blacklist'e al" />
              : ''
            }

            </div>
            <div style={{ display: 'flex' }}>
              <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit() }} type='submit' value='Kapat' />
              {status == 'P' ?
                <div className="" style={{ display: 'flex' }}>
                  < input className='button mr20 reject-button' onClick={() => { DenySubmit() }} type='submit' value='Ret' />
                  <input className='button mr20 confirm-button' onClick={() => { ApproveSubmit() }} type='submit' value='Onay' />
                </div>

                : ''
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
})