import { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { PostApprovmentResultDTO } from "../../app/models/models";
import LoadingComponent from "../../app/layout/LoadingComponent";
import ModalInnerContainer from "../../app/common/modals/ModalInnerContainer";
import ReactDatePicker from "react-datepicker";
import { tr } from 'date-fns/locale';
import { toast } from 'react-toastify'
import DetailsPost from "./DetailsPost";


export default observer(function PostApprover() {
    const { postApproveStore } = useStore();
    const [posts, setPosts] = useState<PostApprovmentResultDTO[]>([]);
    const [filteredPosts, setFilteredPosts] = useState<PostApprovmentResultDTO[]>([]);

    const [postDetailsModalOpen, setPostDetailsModalOpen] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [postId, setPostId] = useState<string>('');
    const [search, setSearch] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [userType, setUserType] = useState('');

    const [sendDate, setSendDate] = useState<any>('');
    const [lastListDate, setLastListDate] = useState<any>('');
    const [firstApproveDate, setFirstApproveDate] = useState<any>('');
    const [approvedDate, setApprovedDate] = useState<any>('');

    const listPerPage = 5;


    useEffect(() => {
        const fetchData = async () => {
            await postApproveStore.loadPosts();
            if (postApproveStore.posts) {
                sortPosts(JSON.parse(JSON.stringify(postApproveStore.posts.postList)))
                setCurrentPage(1)
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(postApproveStore.posts.postList)).length / listPerPage))
                setFilterStatus('all');
                setUserType('all');
            }
        }
        fetchData()
            .catch(console.error);
    }, [])

    useEffect(() => {
        if (postApproveStore.posts) {
            sortPosts(JSON.parse(JSON.stringify(postApproveStore.posts.postList)))
            setPageCount(Math.ceil(JSON.parse(JSON.stringify(postApproveStore.posts.postList)).length / listPerPage))
        }

        setCurrentPage(1)
    }, [postApproveStore.posts])



    const showPostDetailModal = (id: string) => {
        setPostId(id);
        setPostDetailsModalOpen(true);
    }




    const closePostDetailsModal = (reset: boolean) => {
        setPostDetailsModalOpen(false);
        if (reset) {
            clearFilters()
        }
    }

    const sortPosts = (posts: PostApprovmentResultDTO[]) => {
        const sortedPosts = [...posts].sort((a, b) => {
            const statusOrder: { [key: string]: number } = { P: 0, A: 1, D: 2, B: 3 };
            return statusOrder[a.status] - statusOrder[b.status];
        });
        setPosts(sortedPosts);
        setFilteredPosts(sortedPosts);
    }

    const handleSetSearch = (result: any) => {
        setSearch(result)
        setCurrentPage(1);
    }


    const handleFilter = () => {
        if (filterStatus == '' && sendDate == '' && lastListDate == '' && firstApproveDate == '' && approvedDate == '' && search == '') {
            toast.error("En az bir adet arama kriteri giriniz.");
        }
        else {
            var newPost = posts.filter((item) => {
                return filterStatus == 'all' || filterStatus == '' ? item : item.status?.toLowerCase() == filterStatus.toLowerCase();
            }).filter((item) => {
                let fLDate = sendDate == undefined || sendDate == '' ? new Date(1970, 0, 0) : new Date(sendDate);
                let lADate = approvedDate == undefined || approvedDate == '' ? new Date(2100, 11, 30) : new Date(approvedDate);
                let cDate = new Date(parseInt(item.createdAt.slice(6, 10)), parseInt(item.createdAt.slice(3, 5)) - 1, parseInt(item.createdAt.slice(0, 2)), parseInt(item.createdAt.slice(11, 13)), parseInt(item.createdAt.slice(14, 16)))
                let mDate = new Date(2100, 11, 30);
                if (item.approvedAt != '-') {
                    mDate = new Date(parseInt(item.approvedAt.slice(6, 10)), parseInt(item.approvedAt.slice(3, 5)) - 1, parseInt(item.approvedAt.slice(0, 2)), parseInt(item.approvedAt.slice(11, 13)), parseInt(item.approvedAt.slice(14, 16)))
                }
                return (cDate >= fLDate && mDate <= lADate);
            }).filter((item) => {
                return search?.toLowerCase() == '' ? item : item.userName?.toLowerCase().includes(search.toLowerCase());
            }).filter((item) => {
                return userType == '' || userType == 'all' ? item :
                    (userType == "Owner" && item.userType.toLowerCase() == 'keyaccount' || item.userType.toLowerCase() == 'nonkeyaccount') ||
                    (userType == "SSH" && item.userType.toLowerCase() == 'ssh') ||
                    (userType == "Driver" && item.userType.toLowerCase() == 'driver');
            })
            setCurrentPage(1);
            setPageCount(Math.ceil(newPost.length / listPerPage))
            setFilteredPosts(newPost);
            if (newPost.length == 0) {
                toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
            }
        }
    }
    const postStatusHelper = (status: string) => {
        if (status == 'P') return 'Onay Bekliyor';
        if (status == 'A') return 'Onaylandı';
        if (status == 'D') return 'Reddedildi';
        if (status == 'B') return 'Blacklist\'e alındı';
    }

    const clearFilters = () => {
        setFilterStatus('all');
        setUserType('all');
        setSendDate('');
        setLastListDate('');
        setFirstApproveDate('');
        setApprovedDate('');
        setSearch('')
        setCurrentPage(1);
        setPageCount(Math.ceil(posts.length / listPerPage))
        setFilteredPosts(posts);
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }


    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    if (postApproveStore.loadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>

            <ModalInnerContainer width={'1000px'} body={<DetailsPost postId={postId} handleClose={closePostDetailsModal} />} modalTitle='İnteraktif Paylaşım Onay Ekranı' open={postDetailsModalOpen} closeModal={closePostDetailsModal} />

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='HeaderContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px', background: '#32C5FF' }}>
                    <span className='BannerTitle'>İnteraktif Paylaşımlar Onay</span>
                </div>
                <div className='ApproverBodyContainer' style={{ width: '100%' }}>


                    <div className="approverFilter" >
                        <div className="approverSearch" style={{ width: '180px', marginRight: '15px' }} >
                            <p>Kullanıcı Adı</p>

                            <div className="ui category search" style={{ width: '180px' }}>
                                <div className="ui icon input" style={{ width: '180px ' }} >
                                    <input className="prompt" style={{ background: '#F2F2F2' }} type="text" placeholder="Kullanıcı Adı" onChange={(event) => handleSetSearch(event.target.value)} value={search}></input>
                                    <i className="search icon"></i>
                                </div>
                                <div className="results"></div>
                            </div>
                        </div>
                        <div className="userTypeDropdown " style={{ width: '220px', marginRight: '15px' }} >
                            <p>Kullanıcı Tipi</p>


                            <Dropdown style={{
                                width: '220px',
                                backgroundColor: 'rgb(242,242,242)',
                                height: '43px',
                                margin: '0 auto',
                                fontSize: '16px',
                            }}
                                placeholder='Kullanıcı Tipi'
                                fluid
                                selection
                                options={[
                                    { key: 'all', text: "Tümü", value: "all" },
                                    { key: 'Driver', text: "Sürücü", value: "Driver" },
                                    { key: 'Owner', text: "Araç Sahibi", value: "Owner" },
                                    { key: 'SSH', text: "Servis Süreçleri Yetkilisi", value: "SSH" },
                                ]}
                                value={userType}
                                onChange={(event, { value }) => {
                                    if (value != null) {
                                        setUserType(value.toString());
                                    }
                                }}
                            />

                        </div>
                        <div className="statusDropdown " style={{ width: '180px', marginRight: '15px' }} >
                            <p>İşlem Statüsü</p>


                            <Dropdown style={{
                                width: '180px',
                                backgroundColor: 'rgb(242,242,242)',
                                height: '43px',
                                margin: '0 auto',
                                fontSize: '16px',
                            }}
                                placeholder='İşlem Statüsü'
                                fluid
                                selection
                                options={[
                                    { key: 'all', text: "Tümü", value: "all" },
                                    { key: 'A', text: "Onaylandı", value: "A" },
                                    { key: 'D', text: "Reddedildi", value: "D" },
                                    { key: 'P', text: "Onay Bekliyor", value: "P" },
                                    { key: 'B', text: "Blacklist\'e alındı", value: "B" },
                                ]}
                                value={filterStatus}
                                onChange={(event, { value }) => {
                                    if (value != null) {
                                        setFilterStatus(value.toString());
                                    }
                                }}
                            />

                        </div>

                        <div className="Startdate" style={{ display: 'flex', flexDirection: 'column' }} >
                            <p>Gönderilme Tarihi</p>
                            <div className="sDates" style={{ display: 'flex' }}>
                                <div className="ui input icon" style={{ marginRight: '10px' }} >
                                    <ReactDatePicker
                                        autoComplete='off'
                                        locale={tr}
                                        dateFormat={'dd.MM.yyyy'}
                                        name='StartDate'
                                        placeholderText='gg.AA.YYYY'
                                        selected={sendDate}
                                        onChange={setSendDate}
                                        className="dateArea"
                                    />
                                    <i className="calendar icon" ></i>
                                </div>



                            </div>
                        </div>
                        <div className="Enddate" style={{ display: 'flex', flexDirection: 'column' }} >
                            <p>Tamamlanma Tarihi</p>
                            <div className="eDates" style={{ display: 'flex' }}>
                                <div className="ui input icon" style={{ zIndex: '2' }} >
                                    <ReactDatePicker
                                        autoComplete='off'
                                        locale={tr}
                                        dateFormat={'dd.MM.yyyy'}
                                        name='StartDate'
                                        placeholderText='gg.AA.YYYY'
                                        selected={approvedDate}
                                        onChange={setApprovedDate}
                                        className="dateArea"
                                    />
                                    <i className="calendar icon" ></i>
                                </div>
                            </div>
                        </div>
                        <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                            <input className='button  confirm-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                            <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                        </div>
                    </div>

                    <div className="postList" id="approverList" >
                        {filteredPosts.length == 0 ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                                <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />
                            </div>

                            : <table className='postTable' style={{ background: '#32C5FF' }}>
                                <thead className="ApproverTableHeader">
                                    <tr>
                                        <th >Kullanıcı ID</th>
                                        <th >Kullanıcı Adı</th>
                                        <th >Kullanıcı Soyadı</th>
                                        <th >Ürün Grubu</th>
                                        <th >Kullanıcı Tipi</th>
                                        <th >Gönderilme Tarihi</th>
                                        <th >İşlem Tamamlanma Tarihi</th>
                                        <th >İşlem Statüsü</th>
                                        <th >Detay</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {filteredPosts
                                        .slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                        .map((post) => (
                                            <tr key={post.id} >
                                                <td>{post.userPhoneNumber}</td>
                                                <td>{post.userName}</td>
                                                <td>{post.userSurname}</td>
                                                <td>{post.vehicleType == 'Truck' ? 'Kamyon' : post.vehicleType == 'Bus' ? 'Otobüs' : post.vehicleType}</td>
                                                <td>{post.userType == 'KeyAccount' || post.userType == 'NonKeyAccount' ? 'Araç Sahibi'
                                                    : post.userType == 'SSH' ? 'Servis Süreçleri Yetkilisi' : post.userType}</td>
                                                <td>{post.createdAt}</td>
                                                <td>{post.approvedAt}</td>
                                                <td>{postStatusHelper(post.status)}</td>
                                                <td ><a style={{
                                                    cursor: "pointer",
                                                    color: "auto",
                                                }} onClick={() => { showPostDetailModal(post.id) }} >
                                                    Detay
                                                </a></td>

                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        }
                    </div>,
                    {pageCount < 1 ?
                        <></>
                        :
                        <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }}   >{createElements()}</div>
                    }
                </div>
                <div className='FooterContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px' }}>
                </div>

                <script>

                </script>
            </div >
        </>
    )
})