import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Header, Segment } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';

export default observer(function ServerError() {
    const { commonStore } = useStore();
    return (
        <Container>
            <Link to="/accountApprove" className="button" style={{ marginTop: '100px', color: 'white' }}>Ana Sayfaya Dön</Link>
            <Header as='h1' color='red' content='Server Error' />
            <Header sub as='h5' color='red' content={commonStore.serverError?.message} />
            {commonStore.serverError?.details &&
                <Segment>
                    <Header as='h4' content='Stack trace' color='teal' />
                    <code style={{ marginTop: '10px' }}>{commonStore.serverError.details}</code>
                </Segment>

            }
        </Container>
    )
})